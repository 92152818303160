import React from 'react';
import { useContext } from 'react';
import Check from '../../img/check-point.inline.svg';
import './style.scss';
import { LangContext } from '../Layout';

const Item = ({ check }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="check">
      <Check className="check__icon" />
      <div className="check__content">
        <div className="check__content__title">
          {check['title_' + language]}
        </div>
        <div className="check__content__desc">{check['desc_' + language]}</div>
      </div>
    </div>
  );
};

const FastProgression = ({ data }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="fast-progression">
      <div className="fast-progression__title">{data['title_' + language]}</div>
      <div className="fast-progression__subtitle">
        {data['subtitle_' + language]}
      </div>
      <div className="fast-progression__items">
        {data.checks.map((check, index) => (
          <Item check={check} key={index} />
        ))}
      </div>
    </div>
  );
};

export default FastProgression;

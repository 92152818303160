import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import JoinNW from '../components/joinNW/JoinNW';
import Tracks from '../components/tracks/Tracks';
import FastProgression from '../components/fast-progression/FastProgression';

const CareerPageTemplate = ({ data }) => {
  const { markdownRemark: post } = data;
  const { joinNW, fastProgression, tracks } = post.frontmatter;
  return (
    <Layout>
      <JoinNW data={joinNW} />
      <FastProgression data={fastProgression} />
      <Tracks data={tracks} />
    </Layout>
  );
};

CareerPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CareerPageTemplate;

export const careerPageQuery = graphql`
  query CareerPage {
    markdownRemark(frontmatter: { templateKey: { eq: "career-page" } }) {
      frontmatter {
        joinNW {
          title_en
          title_fr
          button_en
          button_fr
          image {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          reasons {
            reason_fr
            reason_en
          }
        }
        fastProgression {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          checks {
            title_en
            title_fr
            desc_en
            desc_fr
          }
        }
        tracks {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          desc_en
          desc_fr
          button_en
          button_fr
          track {
            title_en
            title_fr
            desc_en
            desc_fr
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`;
